import React from 'react';
import 'antd/dist/antd.css';
import ZajilEBooking from './components/pages/ZajilEBooking/ZajilEBooking';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import translations from './translations/eBooking.json';
import zajilCityTranslations from './translations/cityList.json';
import { useSelector } from 'react-redux';
import ar_EG from 'antd/es/locale/ar_EG';
import en_US from 'antd/es/locale/en_US';

function App() {
  const languageConfigMap = {
    ar: ar_EG,
    en: en_US
  }
  const language = useSelector(state => state.translation.language);
  const languageConfig = languageConfigMap[language];

  return (

    <ConfigProvider locale={languageConfig} direction={language === 'ar' ? 'rtl' : 'ltr'}>
      <IntlProvider locale={language} messages={{ ...translations[language], ...zajilCityTranslations[language] }} >
        <div className="App">
          <ZajilEBooking />
        </div>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
