import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Radio, Button, Modal, InputNumber, Row, Col, DatePicker } from 'antd';
import { createUseStyles } from 'react-jss';
import ReCAPTCHA from 'react-google-recaptcha';
import { ORGANISATION_LOGO_URL, GOOGLE_RECAPTCHA_SITE_KEY, zajilServiceTypes, validOriginAreaCodes, validAreaCodes } from '../../../globals';
import { bookingRequest } from '../../../api/zajilBooking';
import zajilCityTranslations from '../../../translations/cityList.json';
import { useIntl } from 'react-intl';
import { setPageLanguage } from '../../../actions/performTranslation';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { range } from '../../../utils/utils'


const styles = createUseStyles({
  header: {
    padding: '8px 24px',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.4)',
    fontSize: 16,
    fontWeight: 600,
    color: '#424242',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    background: '#ffffff',
    zIndex: +1
  },

  bookingFormFields: {
    padding: 36,
    boxSizing: 'border-box',
    '& label::after': {
      content: '"" !important'
    },
    '& label.ant-form-item-required::before': {
      display: 'none'
    },
    '& label.ant-form-item-required::after': {
      display: 'inline-block',
      marginRight: '4px',
      color: '#ff4d4f',
      fontSize: '14px',
      lineHeight: 1,
      content: '"*" !important',
    },
    '& label.ant-radio-wrapper': {
      minWidth: '120px',
    },
    '& .ant-form-item-control-input': {
      maxWidth: '400px',
    },
    '& .ant-input-number ': {
      width: '100%'
    }
  },

  phoneNumberField: {
    '& .ant-input-group-rtl': {
      direction: 'ltr',
      borderLeft: '1px solid #d9d9d9',
    }
  },

  formSectionHeader: {
    fontSize: 16,
    fontWeight: 600,
    color: '#424242',
    marginBottom: 20,
  },
});

const BookingForm = (props: any) => {
  const intl = useIntl();
  const classes = styles();
  const { Option } = Select;
  const [googleCaptha, setGoogleCaptcha] = useState(null);
  const [captchaError, setCaptchaError] = useState('');
  const [processingSubmit, setProcessingSubmit] = useState(false);
  const [formReset, setFormReset] = useState(0);
  const language = useSelector(state => state.translation.language);
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    setGoogleCaptcha(null);
  }, [language]);

  const submitForm = async (values: any) => {
    setProcessingSubmit(true);
    const captcha = googleCaptha;
    if (!captcha) {
      setCaptchaError(intl.formatMessage({ id: 'captchaError' }));
      setProcessingSubmit(false);
      return;
    }
    if (moment.now() > values.pickup_time) {
      modal.error({
        title: 'Could Not Process Request',
        content: 'Pickup Time should be after current time',
        onOk() {
          setProcessingSubmit(false);
        }
      });
      return;
    }
    const requestData = {
      origin_details: {
        name: values.origin_name,
        phone: `966${values.origin_phone}`,
        city: zajilCityTranslations['en'][`city.${values.origin_pincode}`],
        pincode: values.origin_pincode,
        address_line_1: values.origin_address,
      },
      destination_details: {
        name: values.destination_name,
        phone: `966${values.destination_phone}`,
        city: zajilCityTranslations['en'][`city.${values.destination_pincode}`],
        pincode: values.destination_pincode,
        address_line_1: values.destination_address,
      },
      load_type: values.load_type,
      service_type_id: values.service_type_id.toUpperCase(),
      payment_type: values.payment_type,
      weight: Number(values.weight),
      time_slot_start: values.pickup_time.format('YYYY-MM-DD HH:00'),
      declared_value: Number(values.declared_value),
      is_packaging_required: !!values.is_packaging_required,
      national_id: values.national_id,
      captcha,
      num_pieces: Number(values.num_pieces),
      description: values.description,
    }
    // console.log(requestData);
    const response = await bookingRequest(requestData);
    if (response.isSuccess) {
      modal.success({
        title: 'Booking confirmed',
        content: (<p>{intl.formatMessage({ id: 'bookingSuccess' })} <b>{response.data.reference_number}</b></p>),
        onOk() {
          form.resetFields();
          setFormReset(Number(!formReset));
          setProcessingSubmit(false);
        },
      });
    }
    else {
      modal.error({
        title: 'Could Not Process Request',
        content: response.errorMessage,
        onOk() {
          setProcessingSubmit(false);
        }
      });
    }
  }

  const captchaChange = (captchaVal: any) => {
    setGoogleCaptcha(captchaVal);
    if (captchaVal && captchaError) {
      setCaptchaError('');
    }
  };

  function disabledDate(current) {
    // Can not select days before today
    return current < moment().startOf('day');
  }

  function disabledDateTime(date) {
    return {
      disabledHours: () => date > moment.now() ? [] : range(0, moment().format('HH')),
    };
  }

  return (
    <Form
      form={form}
      onFinish={submitForm}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 12 }}
      labelAlign="left"
    >
      {contextHolder}
      <div className={classes.bookingFormFields}>
        <div className={classes.formSectionHeader}>{intl.formatMessage({ id: 'shipperDetails' })} :</div>
        <Form.Item
          label={`${intl.formatMessage({ id: 'name' })} ::`}
          name="origin_name"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder={intl.formatMessage({ id: 'name' })} />
        </Form.Item>
        <Form.Item
          label={`${intl.formatMessage({ id: 'nationalId' })} ::`}
          name="national_id"
          rules={[
            {
              required: true,
              pattern: new RegExp("^[0-9]{10}$"),
              message: intl.formatMessage({ id: 'nationalIdError' }),
            },
          ]}
        >
          <Input placeholder={intl.formatMessage({ id: 'nationalId' })} />
        </Form.Item>
        <Form.Item
          label={`${intl.formatMessage({ id: 'phoneNumber' })} ::`}
          name="origin_phone"
          rules={[
            {
              required: true,
              pattern: new RegExp("^[1-9][0-9]{8}$"),
              message: intl.formatMessage({ id: 'phoneNumberError' })
            },
          ]}
        >
          <Input className={classes.phoneNumberField} addonBefore="+966" placeholder={intl.formatMessage({ id: 'phoneNumber' })} />
        </Form.Item>
        <Form.Item
          label={`${intl.formatMessage({ id: 'city' })} ::`}
          name="origin_pincode"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'cityError' }),
            }
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={intl.formatMessage({ id: 'city' })}
          >
            {validOriginAreaCodes.map(e => <Option style={{ textTransform: 'uppercase' }} value={e}>{intl.formatMessage({ id: `city.${e}` })}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label={`${intl.formatMessage({ id: 'address' })} ::`}
          name="origin_address"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'addressError' }),
            }
          ]}
        >
          <Input.TextArea placeholder={intl.formatMessage({ id: 'address' })} />
        </Form.Item>

        <div className={classes.formSectionHeader} style={{ marginTop: '36px' }}>{intl.formatMessage({ id: 'recipientDetails' })} :</div>
        <Form.Item
          label={`${intl.formatMessage({ id: 'name' })} ::`}
          name="destination_name"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder={intl.formatMessage({ id: 'name' })} />
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'phoneNumber' })} ::`}
          name="destination_phone"
          rules={[
            {
              required: true,
              pattern: new RegExp("^[1-9][0-9]{8}$"),
              message: intl.formatMessage({ id: 'phoneNumberError' })
            },
          ]}
        >
          <Input className={classes.phoneNumberField} addonBefore="+966" placeholder={intl.formatMessage({ id: 'phoneNumber' })} />
        </Form.Item>
        <Form.Item
          label={`${intl.formatMessage({ id: 'city' })} ::`}
          name="destination_pincode"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'cityError' })
            }
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={intl.formatMessage({ id: 'city' })}
          >
            {validAreaCodes.map(e => <Option style={{ textTransform: 'uppercase' }} value={e}>{intl.formatMessage({ id: `city.${e}` })}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label={`${intl.formatMessage({ id: 'address' })} ::`}
          name="destination_address"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'addressError' }),
            }
          ]}
        >
          <Input.TextArea placeholder={intl.formatMessage({ id: 'address' })} />
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'pickupTime' })} ::`}
          name="pickup_time"
          rules={[{ required: true, message: intl.formatMessage({ id: 'pickupTimeError' }) }]}
        >
          <DatePicker
            format='YYYY-MM-DD HH'
            style={{ width: '160px' }}
            placeholder={intl.formatMessage({ id: 'pickupTime' })}
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
            showTime={{ defaultValue: moment(moment().format('HH'), 'HH') }}
          />
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'goodsValue' })} ::`}
          name="declared_value"
          rules={[
            {
              required: true,
              pattern: new RegExp("^[0-9]*$"),
              message: intl.formatMessage({ id: 'goodsValueError' }),
            },
          ]}
        >
          <Input placeholder={intl.formatMessage({ id: 'goodsValue' })} />
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'isPackagingRequired' })} ::`}
          name="is_packaging_required"
          rules={[{ required: false }]}
        >
          <Radio.Group>
            <Radio value={true}>{intl.formatMessage({ id: 'yes' })}</Radio>
            <Radio value={false}>{intl.formatMessage({ id: 'no' })}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'serviceType' })} ::`}
          name="service_type_id"
          rules={[{ required: true, message: intl.formatMessage({ id: 'serviceTypeError' }) }]}
        >
          <Radio.Group>
            {zajilServiceTypes.map(e => <Radio value={e.id}>{intl.formatMessage({ id: e.id })}</Radio>)}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'paymentCollection' })} ::`}
          name="payment_type"
          rules={[{ required: true, message: intl.formatMessage({ id: 'paymentCollectionError' }) }]}
        >
          <Radio.Group>
            <Radio value="PICKUP">{intl.formatMessage({ id: 'pickup' })}</Radio>
            <Radio value="DELIVERY">{intl.formatMessage({ id: 'delivery' })}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'shipmentType' })} ::`}
          name="load_type"
          rules={[{ required: true, message: intl.formatMessage({ id: 'shipmentTypeError' }) }]}
        >
          <Radio.Group>
            <Radio value="DOCUMENT">{intl.formatMessage({ id: 'document' })}</Radio>
            <Radio value="NON-DOCUMENT">{intl.formatMessage({ id: 'nonDocument' })}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'weightKgs' })} ::`}
          name="weight"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'weightError' })
            },
          ]}
        >
          <InputNumber max={30} min={0} placeholder={intl.formatMessage({ id: 'weightKgs' })} />
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'numberOfPieces' })} ::`}
          name="num_pieces"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'numberOfPiecesError' }),
            },
          ]}
        >
          <InputNumber min={1} placeholder={intl.formatMessage({ id: 'numberOfPieces' })} />
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'shipmentDescription' })} ::`}
          name="description"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input maxLength={50} placeholder={intl.formatMessage({ id: 'shipmentDescription' })} />
        </Form.Item>

        <Form.Item
          label=":"
        >
          <div>
            <ReCAPTCHA
              sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
              onChange={captchaChange}
              hl={language}
              key={`${language}${formReset}`}
            />
            <span style={{ color: 'red' }}>{captchaError}</span>
          </div>
        </Form.Item>
        <Form.Item
          label=":"
        >
          <Button style={{ width: "100%" }} size="large" type="primary" htmlType="submit" loading={processingSubmit} >
            {intl.formatMessage({ id: 'submit' })}
          </Button>
        </Form.Item>
      </div>
    </Form>);
};

const ZajilEBooking = (props: any) => {
  const classes = styles();
  const intl = useIntl();
  const language = useSelector(state => state.translation.language);
  const dispatch = useDispatch();
  const handleLanguageChange = e => {
    dispatch(setPageLanguage(e.target.value));
  };

  return (
    <div style={{ marginTop: '56px' }} >
      <Row justify="space-between" className={classes.header}>
        <Col>
          <div>
            <img alt="Organisation Logo" style={{ height: 44, margin: '0px 8px' }} src={ORGANISATION_LOGO_URL} />
            <span style={{ verticalAlign: 'middle' }}>{intl.formatMessage({ id: 'header' })}</span>
          </div>
        </Col>
        <Col>
          <div>
          <Radio.Group value={language} onChange={handleLanguageChange}>
            <Radio.Button value="en">English</Radio.Button>
            <Radio.Button value="ar">عربى</Radio.Button>
          </Radio.Group>
          </div>
        </Col>
      </Row>
      <BookingForm />
    </div>
  );
}

export default ZajilEBooking;
