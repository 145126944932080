import axios from 'axios';

const apiProvider = axios.create({});

apiProvider.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  return Promise.reject(error);
});

export default apiProvider;
