import apiProvider from './provider';
import {
  getAuthenticationHeaders,
  getErrorMessage
} from './utils';
import { ONLINE_BOOKING_BACKEND_V2 } from '../globals';
import { CREATE_BOOKING } from './routes';

export const bookingRequest = async (body) => {
  try {
    const response = await apiProvider.post(
      `${ONLINE_BOOKING_BACKEND_V2}${CREATE_BOOKING}`,
      body,
      {
        headers: getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
